var axios = require("axios").default;
const baseUrl = `https://api.stage.ebsr.in/`;
const localData = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
const token = localData.token
export default class counsellor {
    async counsellorList() {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}counsellor/list`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
               
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };

    async DistrictList() {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}users/district`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };

    async DesginationList() {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}users/role_list`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };

    async HospitalList() {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}users/view/hospital`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };

    async updateCounselor(data) {
        try {
            const response = await axios.put(`${baseUrl}counsellor/update`, data, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })

            return response.data;
        } catch (error) {
            console.error(error);
            return false;
        }

    };
}