var axios = require("axios").default;
const baseUrl = `https://api.stage.ebsr.in/users/`;
const localData = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
const token = localData.token
export default class Master {
    async MedicalDirector() {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}medical_director`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };


    async AsstManager() {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}ast_manager_distribution`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };

    async Manager() {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}manager`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };

    async HCRPManager() {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}hcrp_manager`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };

    async AllupdateSystem(data) {
        try {
            const response = await axios.post(`${baseUrl}/update_other_users`, data, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })

            return response.data;
        } catch (error) {
            console.error(error);
            return false;
        }

    };
}